// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DonationForm from "./DonationForm";
import DonatePage from "./pages/DonatePage";
import SuccessPage from "./pages/SuccessPage";
import PaymentRequestForm from "./components/PaymentRequestForm";

import ScrollToTop from "./components/ScrollToTop";
const apiKey = "pk_live_51MHTSvJob6Z06wXIQKQpBOI9ATfI3fZtTYOPQcGbWdxZ9krIejRxOaM5Xqn5v2oggYb6GVvBUIhnQnkNqH8sbrQF00bBNM7hkX";

const stripePromise = loadStripe(apiKey);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/donate"
            element={
              <Elements stripe={stripePromise}>
                <PaymentRequestForm />
                <DonationForm />
              </Elements>
            }
          />
          <Route
            path="/"
            element={
              <Elements stripe={stripePromise}>
                <DonatePage />
              </Elements>
            }
          />

          <Route
            path="/success"
            element={
              <Elements stripe={stripePromise}>
                <SuccessPage />
              </Elements>
            }
          />

          {/* Add more routes if needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
